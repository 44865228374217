import { useCallback, useState } from 'react'

import { request } from '../../../utils/api'

export const useExportPanopto = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const [isTimeout, setIsTimeout] = useState(false)
  const [isError, setIsError] = useState(false)

  const [data, setData] = useState(null)

  const handleInitialize = useCallback(() => {
    setIsLoading(false)
    setIsSuccess(false)
    setIsError(false)
    setIsTimeout(false)
    setData(false)
  }, [])

  const exportPanopto = useCallback(async ({ folderId, videoId }) => {
    setIsLoading(true)
    setIsSuccess(false)
    setIsError(false)
    setIsTimeout(false)
    setData(null)

    const response = await request({
      method: 'post',
      url: `panopto/export/${videoId}`,
      data: {
        folderId,
      },
      customErrorHandler: () => null,
    })

    setIsLoading(false)

    if (!response) {
      setIsError(true)
      return
    }

    if (response.status === 206) {
      setIsTimeout(true)
    } else {
      setIsSuccess(true)
    }

    setData(response)
  }, [])

  return {
    exportPanopto,
    exportPanoptoData: data,
    exportPanoptoInitialize: handleInitialize,
    exportPanoptoIsLoading: isLoading,
    exportPanoptoIsError: isError,
    exportPanoptoIsSuccess: isSuccess,
    exportPanoptoIsTimeout: isTimeout,
  }
}
