import Icon from '../../../components/Icon'

import { ExportPanoptoModalResultLinks } from './exportPanoptoModalResultLinks'

import { STYLE } from '../constants'

export const ExportPanoptoModalTimeout = ({ openInFolderUrl }) => {
  return (
    <>
      <p>
        <Icon style={STYLE.icon} name="info_folder" />
      </p>

      <h1>Exporting to Panopto...</h1>

      <p>
        <span>Your video is still uploading. Please navigate to your Panopto folder to check the status.</span>
      </p>

      <ExportPanoptoModalResultLinks openInFolderUrl={openInFolderUrl} />
    </>
  )
}
