import { Button, Spin } from 'antd'
import { Component, useEffect } from 'react'
import { Routes, Route, useLocation, Navigate } from 'react-router-dom'
import ReactGA from 'react-ga4'
import { useStore } from './store'
import MainLayout from './layouts/main'
import GuestLayout from './layouts/guest'
import ForgotPassword from './routes/auth/forgotPassword'
import ThinkificInstall from './routes/auth/thinkificInstall'
import Login from './routes/auth/login'
import Signup from './routes/auth/signup'
import Auth from './routes/auth/auth'
import SSOAuth from './routes/auth/ssoAuth'
import { Poll } from './routes/auth/poll/poll'
import Profile from './routes/profile/index'
import Videos from './routes/videos/videos'
import PersonalTemplates from './routes/templates/templates'
import Video from './routes/video'
import VideoStory from './routes/videoStory'
import PreviewMain from './routes/preview/main'
import PreviewGuest from './routes/preview/guest'
import PublicCustomAvatar from './routes/public/promoPages/buyAvatar/index'
import PublicCustomVoice from './routes/public/promoPages/buyVoice/index'
import Academy from './routes/academy'
import CoursePreview from './routes/academy/pages/coursePreview'
import Course from './routes/academy/pages/course'
import UsersAdmin from './routes/admin/users'
import Workspace from './routes/workspace/workspace'
import AdminVideos from './routes/admin/videos'
import AdminTemplates from './routes/admin/templates'
import AdminAvatars from './routes/admin/avatars/avatars'
import AdminAvatar from './routes/admin/avatars/avatar'
import AdminVoices from './routes/admin/voices'
import ManageAvatar from './routes/createAvatar/index'
import ManageVoice from './routes/createAvatar/voice'
import Home from './routes/home/index'
import AvatarsLibrary from './routes/avatars/index'
import ApiConfig from './routes/apiConfig/apiConfig'
import NotFound from './routes/404'
import './style/style.less'
import './layouts/guest.less'
import './assets/css/mdb.flags.min.css'
import SetupSSO from './routes/setup-sso/setup-sso'
import VideoHistory from './routes/history'
import { useExtAppAuth } from './hooks/useExtAppAuth'
import { useElaiNotification } from './hooks/useElaiNotification'
import AdminAcademy from './routes/admin/academy/adminAcademy'
import { AdminLessons } from './routes/admin/academy/lessons'
import { track } from './utils/analytics'
import { createRequestDataForPlanCheckout } from './utils/payment'
import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import HttpBackend from 'i18next-http-backend'

const { REACT_APP_CONF_URL } = process.env

class ErrorBoundary extends Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  componentDidCatch(error) {
    track('app_error_encountered', { error_name: error.name })
  }

  render() {
    if (this.state.hasError) {
      return this.props.fallback
    }

    return this.props.children
  }
}

i18next
  .use(initReactI18next)
  .use(LanguageDetector)
  .use(HttpBackend)
  .init({
    lng: 'en',
    fallbackLng: 'en',
    backend: {
      loadPath: `${REACT_APP_CONF_URL}/${window.location.hostname}/translation.json`,
      parse: (data) => {
        const allTranslations = JSON.parse(data)
        return allTranslations[i18next.language].translation || allTranslations.en.translation
      },
      request: (options, url, payload, callback) => {
        if (!REACT_APP_CONF_URL) return callback(null, { status: 200, data: {} })
        fetch(url).then((response) => {
          if (response.ok) {
            return callback(null, { status: 200, data: response.json() })
          } else {
            return callback(response, null)
          }
        })
      },
    },
    // debug: true,
  })

function App() {
  const authStore = useStore((stores) => stores.authStore)
  const { addLocationToNavHistory } = useStore((stores) => stores.navigationStore)
  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const message = params.get('message')
  const notification = useElaiNotification()
  if (message)
    notification[params.get('messageType') || 'success']({
      duration: false,
      message,
    })

  const { isExternalAuth } = useExtAppAuth()

  /**
   * add current location to a navigation history state
   */
  useEffect(() => addLocationToNavHistory(location.pathname), [location.pathname])

  /**
   * Trigger on route change
   */
  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: location.pathname })
  }, [location?.pathname])

  /**
   * Add payment plan to localStorage to display checkout page
   */
  const purchaseData = new URLSearchParams(window.location.search).get('purchase')
  if (purchaseData) localStorage.setItem('purchase', JSON.stringify(createRequestDataForPlanCheckout(purchaseData)))

  if (isExternalAuth()) return <Spin size="large" className="auth-spin" />

  const backUrl = params.get('backUrl')
  return (
    <ErrorBoundary
      fallback={
        <>
          <h1 style={{ margin: '20px' }}>
            <center>Something went wrong. Please refresh a page.</center>
          </h1>
          <center>
            <Button type="primary" size="large" onClick={() => window.location.reload()}>
              Refresh
            </Button>
          </center>
        </>
      }
    >
      <Routes>
        <Route
          path="/"
          element={
            authStore.user ? backUrl ? <Navigate replace to="/" /> : <MainLayout /> : <Navigate replace to="/login" />
          }
        />
        <Route element={<GuestLayout />}>
          <Route path="login" element={<Login />} />
          <Route path="signup" element={<Signup />} />
          <Route path="auth" element={<Auth />} />
          <Route path="sso" element={<SSOAuth />}>
            <Route path=":integration" element={<SSOAuth />} />
          </Route>
          <Route path="poll" element={<Poll />} />
          <Route path="install" element={<ThinkificInstall />} />
          <Route path="forgot-password" element={<ForgotPassword />} />
          <Route path="/v/:id" element={<PreviewGuest />} />
          <Route path="/buy-avatar" element={<PublicCustomAvatar />} />
          <Route path="/buy-voice" element={<PublicCustomVoice />} />
          <Route path="/academy">
            <Route path="" element={<Academy />} />
            <Route path="course-preview/:id" element={<CoursePreview />} />
          </Route>
          <Route path="*" element={<NotFound />} />
        </Route>
        <Route element={<MainLayout />}>
          <Route index element={<Home />} />
          <Route path="video/:id">
            <Route path=":slideId" element={<Video />} />
            <Route path="" element={<Video />} />
          </Route>
          <Route path="videos" element={<Videos />}>
            <Route path=":folderId" element={<Videos />} />
          </Route>
          <Route path="deleted-videos" element={<Videos />} />
          <Route path="profile" element={<Profile />} />
          <Route path="history/:id" element={<VideoHistory />} />
          <Route path="workspace" element={<Workspace />} />
          <Route path="templates" element={<PersonalTemplates />} />
          <Route path="api" element={<ApiConfig />} />
          <Route path="setup-sso" element={<SetupSSO />} />
          <Route path="avatars" element={<AvatarsLibrary />} />
          <Route path="/preview/:id" element={<PreviewMain />} />
          <Route path="/avatar/:id" element={<ManageAvatar />} />
          <Route path="/add-voice-data" element={<ManageVoice />} />
          <Route path="/story/:videoId" element={<VideoStory />} />
          <Route path="/academy">
            <Route path="" element={<Academy />} />
            <Route path="course/:id">
              <Route path=":lessonId" element={<Course />} />
              <Route path="" element={<Course />} />
            </Route>
          </Route>
          {(!authStore.user || authStore.user.isAdmin) && (
            <Route path="/admin">
              <Route path="users" element={<UsersAdmin />} />
              <Route path="videos" element={<AdminVideos />} />
              <Route path="templates" element={<AdminTemplates />} />
              <Route path="avatars" element={<AdminAvatars />} />
              <Route path="avatar/:id" element={<AdminAvatar />} />
              <Route path="voices" element={<AdminVoices />} />
              <Route path="courses" element={<AdminAcademy />} />
              <Route path="courses/:id" element={<AdminLessons />} />
            </Route>
          )}
        </Route>
      </Routes>
    </ErrorBoundary>
  )
}

export default App
