import { Modal, Button } from 'antd'

import { useExportPanoptoModal } from './hooks/useExportPanoptoModal'

import { ExportPanoptoModalError } from './components/exportPanoptoModalError'
import { ExportPanoptoModalSuccess } from './components/exportPanoptoModalSuccess'
import { ExportPanoptoModalContent } from './components/exportPanoptoModalContent'
import { ExportPanoptoModalTimeout } from './components/exportPanoptoModalTimeout'
import { ExportPanoptoModalLoading } from './components/exportPanoptoModalLoading'

const STYLE = {
  contentWrapper: { marginTop: '8px' },
  modalBody: { padding: 15 },
}

export const ExportPanoptoModal = ({ video, isOpen, setIsOpen }) => {
  const {
    folder,
    setFolder,
    handleSubmit,
    handleClose,
    exportPanoptoInitialize,
    exportPanoptoIsLoading,
    exportPanoptoIsError,
    exportPanoptoIsSuccess,
    exportPanoptoIsTimeout,
    isInitial,
    openInFolderUrl,
    viewInPanoptoUrl,
    hasUnsupportedElements,
  } = useExportPanoptoModal({
    video,
    setIsOpen,
  })

  if (!video) return null

  return (
    <Modal
      centered
      open={isOpen}
      width={500}
      bodyStyle={STYLE.modalBody}
      footer={
        isInitial ? (
          <Button
            type="primary"
            key="export"
            onClick={handleSubmit}
            disabled={exportPanoptoIsLoading || !folder}
            loading={exportPanoptoIsLoading}
          >
            Export
          </Button>
        ) : null
      }
      onCancel={handleClose}
      afterClose={exportPanoptoInitialize}
    >
      <div style={STYLE.contentWrapper}>
        {isInitial && (
          <ExportPanoptoModalContent
            hasUnsupportedElements={hasUnsupportedElements}
            folder={folder}
            setFolder={setFolder}
          />
        )}
        {exportPanoptoIsLoading ? <ExportPanoptoModalLoading openInFolderUrl={openInFolderUrl} /> : null}
        {exportPanoptoIsError && <ExportPanoptoModalError />}
        {exportPanoptoIsSuccess && (
          <ExportPanoptoModalSuccess openInFolderUrl={openInFolderUrl} viewInPanoptoUrl={viewInPanoptoUrl} />
        )}
        {exportPanoptoIsTimeout && <ExportPanoptoModalTimeout openInFolderUrl={openInFolderUrl} />}
      </div>
    </Modal>
  )
}
