import { request } from '../../../../../utils/api'

const PAGE_SIZE = 1000
const START_PAGE = 0

const fetchPanoptoFolders = async (pageNumber) => {
  const response = await request({
    method: 'get',
    url: `panopto/folders/creator?pageNumber=${pageNumber}`,
  })

  if (!Array.isArray(response)) {
    throw new Error('Failed to load folders')
  }

  return response
}

export const fetchAllPanoptoFolders = async () => {
  const response = await fetchPanoptoFolders(START_PAGE)

  if (!(response?.length >= PAGE_SIZE)) {
    return response
  }

  const pages = [response]

  while (pages[pages.length - 1].length >= PAGE_SIZE) {
    const nextPageNumber = pages.length
    const nextPage = await fetchPanoptoFolders(nextPageNumber)
    pages.push(nextPage)
  }

  return pages.flat()
}
