import { Row, Col } from 'antd'

import Icon from '../../../components/Icon'

const STYLE = {
  wrapper: {
    borderRadius: '8px',
    padding: '16px',
    backgroundColor: '#bdbdbd1a',
    backgroundOpacity: '0.1',
  },
  icon: {
    fontSize: '16px',
    color: '#ffaf36',
  },
}

export const ExportPanoptoModalWarningMessage = ({ message }) => {
  return (
    <div style={STYLE.wrapper}>
      <Row gutter={14} wrap={false}>
        <Col flex="none">
          <Icon style={STYLE.icon} name="warning" />
        </Col>
        <Col flex="auto">
          <span>{message}</span>
        </Col>
      </Row>
    </div>
  )
}
