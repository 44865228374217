import { Spin } from 'antd'

import { ExportPanoptoModalResultLinks } from './exportPanoptoModalResultLinks'

export const ExportPanoptoModalLoading = ({ openInFolderUrl }) => {
  return (
    <>
      <p>
        <Spin size="large" />
      </p>

      <h1>Exporting to Panopto...</h1>

      <p>
        <span>Upload in progress. This page will update when your upload is complete.</span>
      </p>

      <ExportPanoptoModalResultLinks openInFolderUrl={openInFolderUrl} withViewInPanoptoLink />
    </>
  )
}
