import Icon from '../../../components/Icon'

import { ExportPanoptoModalResultLinks } from './exportPanoptoModalResultLinks'

import { STYLE } from '../constants'

export const ExportPanoptoModalSuccess = ({ openInFolderUrl, viewInPanoptoUrl }) => {
  return (
    <>
      <p>
        <Icon style={STYLE.successIcon} name="check_circle" />
      </p>

      <h1>Export completed</h1>

      <p>
        <span>Your video has been exported to Panopto.</span>
      </p>

      <ExportPanoptoModalResultLinks
        openInFolderUrl={openInFolderUrl}
        viewInPanoptoUrl={viewInPanoptoUrl}
        withViewInPanoptoLink
      />
    </>
  )
}
