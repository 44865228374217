export const exportPanoptoCheckUnsupportedElements = (video) => {
  const playerData = video?.playerData

  if (!playerData) {
    return false
  }

  const hasInteractiveElements = playerData?.slides?.some(({ objects }) => objects?.length)
  const hasChapters = playerData?.slides?.some(({ chapterEnabled, chapterTitle }) => chapterEnabled || chapterTitle)
  const hasSubtitles = !!playerData?.subtitlesUrl

  return [hasInteractiveElements, hasSubtitles, hasChapters].some(Boolean)
}
