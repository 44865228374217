import React, { createContext, useContext } from 'react'

const SlideListIsBuilderReadyContext = createContext(false)
const SlideListVideoContext = createContext({})

/**
 * SlideListProvider is a context provider that helps pass specific props to
 * nested components without triggering unnecessary re-renders of the entire tree.
 *
 * It utilizes separate contexts to allow selective updates, ensuring that only
 * the components consuming a particular context value are affected when it changes.
 *
 * Custom hooks are provided for convenient access to the context values.
 */
export const SlideListProvider = (props) => {
  const { isBuilderReady, video, children } = props

  return (
    <SlideListIsBuilderReadyContext.Provider value={isBuilderReady}>
      <SlideListVideoContext.Provider value={video}>{children}</SlideListVideoContext.Provider>
    </SlideListIsBuilderReadyContext.Provider>
  )
}

export const useIsBuilderReady = () => {
  return useContext(SlideListIsBuilderReadyContext)
}

export const useVideo = () => {
  return useContext(SlideListVideoContext)
}
