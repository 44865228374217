import { fabric } from 'fabric'

function _convertObjectToPath(obj) {
  if (obj.path) {
    return obj.path
  }

  if (obj.type === 'circle') {
    const { left, top, radius } = obj
    const cx = left + radius
    const cy = top + radius
    return [
      ['M', cx - radius, cy],
      ['a', radius, radius, 0, 1, 0, 2 * radius, 0],
      ['a', radius, radius, 0, 1, 0, -2 * radius, 0],
      ['z'],
    ]
  }

  if (obj.type === 'ellipse') {
    const { left, top, rx, ry } = obj
    const cx = left + rx
    const cy = top + ry
    return [['M', cx - rx, cy], ['a', rx, ry, 0, 1, 0, 2 * rx, 0], ['a', rx, ry, 0, 1, 0, -2 * rx, 0], ['z']]
  }

  if (obj.type === 'rect') {
    const { left, top, width, height } = obj
    const rx = obj.rx || 0
    const ry = obj.ry || rx

    if (!rx && !ry) {
      return [
        ['M', left, top],
        ['L', left + width, top],
        ['L', left + width, top + height],
        ['L', left, top + height],
        ['z'],
      ]
    }

    const right = left + width
    const bottom = top + height

    const rrx = Math.min(rx, width / 2)
    const rry = Math.min(ry, height / 2)

    return [
      ['M', left + rrx, top],
      ['L', right - rrx, top],
      ['A', rrx, rry, 0, 0, 1, right, top + rry],
      ['L', right, bottom - rry],
      ['A', rrx, rry, 0, 0, 1, right - rrx, bottom],
      ['L', left + rrx, bottom],
      ['A', rrx, rry, 0, 0, 1, left, bottom - rry],
      ['L', left, top + rry],
      ['A', rrx, rry, 0, 0, 1, left + rrx, top],
      ['z'],
    ]
  }

  return null
}

const Frame = fabric.util.createClass(fabric.Path, {
  type: 'frame',
  objectCaching: false,

  initialize: function (path, options) {
    options || (options = {})
    this.img = options.img
    this.imgSrc = options.imgSrc
    this.svgWidth = options.svgWidth
    this.patternWidth = options.patternWidth
    this.animation = options.animation
    this.img.scaleToWidth(options.patternWidth)
    this.img.top = 0
    this.img.left = 0
    this.patternSourceCanvas = new fabric.StaticCanvas(null, { enableRetinaScaling: false })
    this.patternSourceCanvas.add(this.img)
    this.patternSourceCanvas.setDimensions({
      width: this.img.getScaledWidth(),
      height: this.img.getScaledHeight(),
    })
    this.callSuper('initialize', path, options)
    this.patternSourceCanvas.renderAll()
    this.fill = new fabric.Pattern({
      source: this.patternSourceCanvas.getElement(),
      repeat: 'repeat',
      offsetX: this.fill.offsetX || 0,
      offsetY: this.fill.offsetY || 0,
    })
    this.setControlsVisibility({ mb: false, ml: false, mr: false, mt: false, mtr: false })
  },

  _draw: function () {
    this.patternSourceCanvas.clear()
    this.img.scaleToWidth(this.patternWidth)
    this.patternSourceCanvas.setDimensions({
      width: this.img.getScaledWidth(),
      height: this.img.getScaledHeight(),
    })
    this.patternSourceCanvas.add(this.img)
    this.patternSourceCanvas.renderAllSafe()
  },

  set: function (key, value) {
    if (typeof key === 'object') this._setObject(key)
    else this._set(key, value)
    if (key === 'scale') {
      this.patternWidth = value
      this._draw()
    } else if (key === 'offsetX' || key === 'offsetY') this.fill[key] = value
    return this
  },

  setAsync: async function (key, value) {
    this._set(key, value)
    if (key === 'image') {
      this.imgSrc = value
      this.img = await fabric.imageFromURL(value)
      this._draw()
      this.canvas.requestRenderAll()
    }
    return this
  },

  toObject: function () {
    return fabric.util.object.extend(this.callSuper('toObject'), {
      id: this.id,
      imgSrc: this.imgSrc,
      patternWidth: this.patternWidth,
      svgWidth: this.svgWidth,
      animation: this.animation,
      meta: this.meta,
    })
  },
})

Frame.fromObject = async function (object, callback) {
  fabric
    .imageFromURL(object.imgSrc)
    .then((img) => {
      img.scaleToWidth(object.patternWidth / 2)
      object.img = img
      const frame = new fabric.Frame(object.path, object)
      callback(frame)
    })
    .catch(() => callback(null, true))
}

Frame.fromImage = async function (svgSrc, img) {
  const svg = await fabric.svgFromURL(svgSrc)

  const path = _convertObjectToPath(svg)
  if (!path) return false
  const initWidth = img.getScaledWidth()
  const imgSrc = img.toObject().src
  const frame = new fabric.Frame(path, {
    id: img.id,
    top: img.top,
    left: img.left,
    imgSrc,
    img,
    svgWidth: svg.width,
    patternWidth: svg.width,
    animation: img.animation,
  })
  frame.scaleToWidth(initWidth)
  return frame
}

Frame.async = true

export default Frame
