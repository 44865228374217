import { useEffect } from 'react'
import { SaveOutlined, CloseOutlined } from '@ant-design/icons'
import { Card, Button, Select, Form, Space, Switch, Collapse, Radio, Input } from 'antd'

import { useStore } from '../../../store'
import { request } from '../../../utils/api'
import { useElaiNotification } from '../../../hooks/useElaiNotification'

const { Panel } = Collapse

const closedIcon = <CloseOutlined />

export const SlideAdmin = (props) => {
  const { data, video, fetchVideo, updateSlide, updateVideo } = props

  const notification = useElaiNotification()
  const videosStore = useStore((stores) => stores.videosStore)
  const isAdmin = useStore((stores) => stores.authStore.user.isAdmin)

  if (!isAdmin) return null

  const [form] = Form.useForm()

  useEffect(() => {
    if (video.template) form.setFieldsValue(video.template)
  }, [video?.template])

  const download = async (pathS3) => {
    notification.info({ message: 'Downloading slide avatar media...', key: 'download-avatar-media' })
    const file = await request({
      method: 'post',
      data: { pathS3 },
      url: `/admin/videos/download`,
      responseType: 'blob',
    })
    const url = window.URL.createObjectURL(new Blob([file]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', `${video._id}_${data.id}_${pathS3.split('/').pop().replace(/\?.+/, '')}`)
    link.click()
    console.log({ link, url })
    notification.destroy('download-avatar-media')
  }

  const handleDecline = async () => {
    await request({ method: 'post', url: `/admin/videos/decline/${video._id}` })
    await fetchVideo()
  }

  const handleSlideAvatar = () => download(data.internal.avatarS3)
  const handleAudio = () => download(data.internal.audioS3)
  const handleSlideAvatarAlpha = () => download(data.internal.avatarAlphaS3)
  const avatarPause = (e) => {
    const pause = e.target.value ? parseInt(e.target.value) : undefined
    updateSlide({ avatar: { ...data.avatar, pause }, status: 'edited' })
  }
  const handleSubmitForm = async ({ enabled, private: privateValue, tags }) => {
    video.template = {
      ...video.template,
      enabled,
      private: privateValue,
      tags,
    }
    if (
      !(await request({
        method: 'patch',
        url: `/videos/${video._id}`,
        data: {
          template: video.template,
        },
      }))
    ) {
      return fetchVideo()
    }
    notification.success({ message: 'Template data was saved' })
    fetchVideo()
    videosStore.fetchTemplates()
  }

  const handleLipsyncModel = (event) => {
    updateSlide({
      avatar: { ...data.avatar, lipsyncModel: event.target.value },
      status: 'edited',
    })
  }

  const applyFilesChangesToAllSlides = () => {
    const slides = video.slides.map((slide) =>
      data.avatar.code === slide.avatar.code
        ? {
            ...slide,
            avatar: { ...slide.avatar, pause: data.avatar.pause, lipsyncModel: data.avatar.lipsyncModel },
            status: 'edited',
          }
        : slide,
    )
    updateVideo({ slides })
  }

  return (
    <div className="admin-controls" translate="no">
      <Card>
        <h3>
          Slide {data.id} - {data.status}
        </h3>
        <Collapse>
          <Panel header="Moderation" key="moderation">
            <Button key="decline" icon={closedIcon} danger onClick={handleDecline}>
              Decline
            </Button>
            <pre>{JSON.stringify(video.moderation, null, 4)}</pre>
            <p>{video.moderation?.text}</p>
          </Panel>
          <Panel header="Files" key="slide">
            {data.internal?.audioS3 && (
              <a onClick={handleAudio} target="_blank" rel="noreferrer">
                🔉 Slide speech mp3
              </a>
            )}
            <br />
            {data.internal?.avatarS3 && <a onClick={handleSlideAvatar}>🙍‍♀️ Slide avatar video</a>}
            <br />
            {data.internal?.avatarAlphaS3 && <a onClick={handleSlideAvatarAlpha}>🤖 Slide avatar alpha</a>}
            <Form.Item label={`Avatar pause frame${data.avatar.pause ? ` (~${data.avatar.pause / 25}s)` : ''}`}>
              <Space>
                <Input value={data.avatar.pause} onChange={avatarPause}></Input>
              </Space>
            </Form.Item>
            <Form.Item label="Lipsync model">
              <Space>
                <Radio.Group value={data.avatar.lipsyncModel}>
                  <Radio.Button value={undefined} onChange={handleLipsyncModel}>
                    Default
                  </Radio.Button>
                  <Radio.Button value="wav2lip" onChange={handleLipsyncModel}>
                    Wav2lip
                  </Radio.Button>
                  <Radio.Button value="faceformer" onChange={handleLipsyncModel}>
                    Faceformer
                  </Radio.Button>
                </Radio.Group>
              </Space>
            </Form.Item>
            <Space>
              <Button type="primary" onClick={applyFilesChangesToAllSlides}>
                Apply to all slides
              </Button>
            </Space>
          </Panel>
          <Panel header="Templates" key="templates">
            <Form form={form} onFinish={handleSubmitForm} initialValues={video.template}>
              <Space size="large" align="baseline">
                <Form.Item name="enabled" label="Enabled" valuePropName="checked">
                  <Switch />
                </Form.Item>
                <Form.Item name="private" label="Private" valuePropName="checked">
                  <Switch />
                </Form.Item>
              </Space>
              <Form.Item name="tags" label="Tags">
                <Select mode="tags" placeholder="Templates categories">
                  {videosStore.templateTags.map((tag) => (
                    <Select.Option key={tag}>{tag}</Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Space>
                <Button type="primary" htmlType="submit" icon={<SaveOutlined />}>
                  Save
                </Button>
              </Space>
            </Form>
          </Panel>
        </Collapse>
      </Card>
    </div>
  )
}
