import React, { useEffect } from 'react'
import axios from 'axios'
import Dompurify from 'dompurify'
import { Spin } from 'antd'
import { useNavigate } from 'react-router-dom'
import { getAuthHeaders, request } from '../../utils/api'
import { useLocation, useParams } from 'react-router'
import { useStore } from '../../store'
import { useElaiNotification } from '../../hooks/useElaiNotification'
import { getDefaultRedirectUrl } from '../../utils/navigation'
import configService from '../../utils/config'
import useAppRedirect from '../../hooks/useAppRedirect'

export default () => {
  const notification = useElaiNotification()
  const { search } = useLocation()
  const authStore = useStore((stores) => stores.authStore)
  const navigate = useNavigate()
  const { integration } = useParams()
  const params = new URLSearchParams(search)
  const code = params.get('code')
  const { apiUrl } = configService.get().urls

  const { navigateToOnboardingVideo } = useAppRedirect()

  const ssoAuth = async () => {
    try {
      const { data } = await axios.get(apiUrl + '/auth/sso/callback' + search, {
        headers: { ...(authStore.user ? getAuthHeaders() : {}) },
        withCredentials: true,
      })
      // Clear user data like subAccountId to ensure we get the correct user state
      authStore.logout()
      await authStore.login(data)
      const redirectUrl = localStorage.getItem('redirectUrl') || data.redirectUrl
      localStorage.removeItem('redirectUrl')
      if (redirectUrl) window.location.href = redirectUrl
      else {
        notification.success({
          message: data.message,
        })
        navigate(getDefaultRedirectUrl(data))
      }
    } catch (error) {
      const message = error.response?.data?.message || error.message
      notification.error({ message, duration: false })
      navigate('/')
    }
  }

  const handlePanoptoSso = async () => {
    const queryParams = window.location.hash.replace(/^#/, '').replace('provider=panopto&', '')
    const url = 'auth/sso/callback?provider=panopto&' + queryParams
    const data = await request({ method: 'get', url })

    if (!data) return navigate('/login')
    const { isNewPanoptoUser, redirectUrl } = data
    if (redirectUrl) return (window.location.href = redirectUrl)

    await authStore.login(data)
    if (isNewPanoptoUser) await navigateToOnboardingVideo({ isNewPanoptoUser })
    else navigate('/')
  }

  const handlePanoptoError = () => {
    const queryParams = new URLSearchParams(window.location.hash.replace(/^#/, ''))
    if (!queryParams.has('error')) return
    notification.error({ message: Dompurify.sanitize(queryParams.get('error')), key: 'panopto-error', duration: 0 })
    navigate('/login')
  }

  useEffect(() => {
    // Panopto Oauth sends parameters as a hash. Check for code, access_token existence
    if (window.location.hash.includes('code=') && window.location.hash.includes('access_token=')) handlePanoptoSso()
    else if (window.location.hash.includes('error=')) handlePanoptoError()
    else if (integration) {
      const redirectUrl = params.get('redirect_url') || params.get('redirectUrl')
      if (redirectUrl) localStorage.setItem('redirectUrl', redirectUrl)
      window.location.href = apiUrl + '/auth/sso/login?integration=' + integration
    } else if (code) {
      ssoAuth()
    } else {
      const params = new URLSearchParams(window.location.search)
      const error = params.get('error')
      const errorDescription = params.get('error_description')
      if (error) {
        notification.error({
          message: error,
          description: errorDescription || 'Unknown error',
          duration: false,
        })
      }
      navigate('/')
    }
  }, [])

  return <Spin size="large" />
}
