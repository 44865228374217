import { Button, Popover, Tooltip } from 'antd'
import { memo, useState } from 'react'
import useClickOutside from '../../../../../hooks/useClickOutside'
import Icon from '../../../../../components/Icon'

const SlideNumberContent = memo((props) => {
  const { interactions, slideIndex, interactionsPopoverContentRef, interactivityButtonRef, updateActiveSlide } = props

  const [isOpenInteractionsPopover, setIsOpenInteractionsPopover] = useState(false)

  const openInteractionsPopover = () => {
    setIsOpenInteractionsPopover(true)
  }

  const closeInteractionsPopover = () => {
    setIsOpenInteractionsPopover(false)
  }

  const onClickSlideButton = (interaction) => {
    if (interaction.slide !== 'end') updateActiveSlide(interaction.slideIndex)
    closeInteractionsPopover()
  }

  useClickOutside([interactionsPopoverContentRef, interactivityButtonRef], closeInteractionsPopover)

  return (
    <div className="slide-number-wrapper">
      <span className="slide-number">{slideIndex + 1}</span>
      {!!interactions.length && (
        <>
          <Icon name="down_full_arrow" className="arrow-icon" />
          {interactions.length > 1 ? (
            <Popover
              open={isOpenInteractionsPopover}
              trigger="click"
              placement="rightTop"
              arrow={false}
              overlayClassName="interactions-popover"
              title={null}
              content={
                <div ref={interactionsPopoverContentRef} className="popover-content-wrapper">
                  <div className="btn-close-wrapper">
                    <Button className="btn-close" size="small" type="text" onClick={closeInteractionsPopover}>
                      <Icon name="close_simple" />
                    </Button>
                  </div>
                  <div className="popover-content">
                    <p className="description">This slide has several interactions. Choose the one to navigate to.</p>
                    <div className="interactions-buttons-container">
                      {interactions.map((interaction, index) =>
                        interaction.type === 'slide' ? (
                          <Button
                            key={index}
                            className={`btn-interaction ${interaction.slide === 'end' ? 'end' : null}`}
                            size="small"
                            onClick={() => onClickSlideButton(interaction)}
                          >
                            {interaction.slide === 'end' ? <Icon name="end_text" /> : interaction.slideIndex + 1}
                          </Button>
                        ) : (
                          <Tooltip key={index} title={interaction.link}>
                            <Button
                              href={interaction.link}
                              target="_blank"
                              rel="noreferrer"
                              className="btn-interaction link"
                              size="small"
                              onClick={closeInteractionsPopover}
                            >
                              <Icon name="link" />
                            </Button>
                          </Tooltip>
                        ),
                      )}
                    </div>
                  </div>
                </div>
              }
            >
              <Button
                ref={interactivityButtonRef}
                size="small"
                className={`btn-interactivity slide-branch ${isOpenInteractionsPopover ? 'active' : ''}`}
                onClick={openInteractionsPopover}
              >
                <Icon name="site_map" />
              </Button>
            </Popover>
          ) : interactions[0].type === 'slide' ? (
            <Button
              size="small"
              className="btn-interactivity slide"
              onClick={() => updateActiveSlide(interactions[0].slideIndex)}
            >
              <span className="interactivity-slide-number">{interactions[0].slideIndex + 1}</span>
              <Icon name="account" />
            </Button>
          ) : interactions[0].type === 'link' ? (
            <Button
              href={interactions[0].link}
              target="_blank"
              rel="noreferrer"
              size="small"
              className="btn-interactivity link"
            >
              <Icon name="link" />
            </Button>
          ) : null}
        </>
      )}
    </div>
  )
})

SlideNumberContent.displayName = 'SlideNumberContent'

export { SlideNumberContent }
