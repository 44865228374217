import { Button } from 'antd'
import React, { memo, useMemo, useRef } from 'react'
import { PlusOutlined } from '@ant-design/icons'
import { Scrollbars } from 'react-custom-scrollbars'

import SlideItem from './components/item'
import { SlideListProvider } from './slideListContext'
import { useSlideListState } from './useSlideList'
import { TransitionModal } from './components/transitionModal'
import ShortcutTooltip from '../shortcutTooltip'

import './slideList.less'
import { useStore } from '../../../../store'
import { MAX_SLIDES_COUNT_PAID, MAX_SLIDES_COUNT_TRIAL } from '../../constants'

const plusIcon = <PlusOutlined />

const minHeight400 = { minHeight: 400 }

const addButtonLimitContent = {
  [MAX_SLIDES_COUNT_TRIAL]: 'Upgrade to Advanced or Enterprise plan to use more than 3 slides.',
  [MAX_SLIDES_COUNT_PAID]: 'Only 100 slides per video are allowed.',
}

const SlideListContent = memo((props) => {
  const {
    video,
    isBuilderReady,
    thumbnails,
    activeSlide,
    canvasActiveObject,
    stableUpdateVideo,
    stableUpdateActiveSlide,
    playerCanvasReady,
    playerActivePreview,
    playerStatus,
    addSlide,
    stableDeleteSlide,
    stableDuplicateSlide,
  } = props

  const {
    stableMoveSlide,
    transitionModal,
    applyTransitionDisabledTitle,
    activeTransition,
    setTransitionModal,
    setActiveTransition,
    handleSetTransition,
    applyTransitionToAllSlides,
    closeTransitionModal,
  } = useSlideListState({ video, stableUpdateVideo, stableUpdateActiveSlide, canvasActiveObject, activeSlide })

  const isBuilderReadyRef = useRef(isBuilderReady)
  isBuilderReadyRef.current = isBuilderReady

  const { user } = useStore((stores) => stores.authStore)
  const slidesLimit = user.account.status === 'trial' ? MAX_SLIDES_COUNT_TRIAL : MAX_SLIDES_COUNT_PAID

  const isSlidesLimitReached = video?.slides.length >= slidesLimit

  const addButton = useMemo(
    () =>
      !playerActivePreview && video.status !== 'validating' ? (
        <ShortcutTooltip
          title="Add slide"
          overrideTitle={isSlidesLimitReached ? addButtonLimitContent[slidesLimit] : null}
          keyName="N"
          placement="top"
          showMetaKey={false}
        >
          <Button
            type="dashed"
            translate="no"
            icon={plusIcon}
            size="large"
            style={{ width: '185px' }}
            className="btn-add"
            onClick={addSlide}
            disabled={isSlidesLimitReached || !playerCanvasReady}
          >
            Add slide
          </Button>
        </ShortcutTooltip>
      ) : null,
    [playerActivePreview, playerCanvasReady, slidesLimit, video?.status, isSlidesLimitReached],
  )

  return (
    <>
      <Scrollbars className="slides-scrollbar scrollbar" style={minHeight400}>
        <div className="slides-container">
          {video.slides &&
            video.slides.map((slide, i) => (
              <SlideItem
                index={i}
                key={slide.id}
                slide={slide}
                isLastSlide={i === video.slides.length - 1}
                isSlidesLimitReached={isSlidesLimitReached}
                isMoreThanOneSlide={video.slides.length > 1}
                stableDuplicateSlide={stableDuplicateSlide}
                stableMoveSlide={stableMoveSlide}
                stableDeleteSlide={stableDeleteSlide}
                videoFormat={video.data?.format}
                stableUpdateActiveSlide={stableUpdateActiveSlide}
                isBuilderReadyRef={isBuilderReadyRef}
                isActive={activeSlide === i}
                playerActivePreview={playerActivePreview}
                playerStatus={playerStatus}
                setTransitionModal={setTransitionModal}
                thumbnail={thumbnails[slide.id]}
              />
            ))}
        </div>
      </Scrollbars>
      {addButton}
      <TransitionModal
        transitionModal={transitionModal}
        applyTransitionDisabledTitle={applyTransitionDisabledTitle}
        activeTransition={activeTransition}
        handleSetTransition={handleSetTransition}
        applyTransitionToAllSlides={applyTransitionToAllSlides}
        setActiveTransition={setActiveTransition}
        closeTransitionModal={closeTransitionModal}
      />
    </>
  )
})

SlideListContent.displayName = 'SlideListContent'

export const SlideList = (props) => {
  const { video, isBuilderReady } = props
  return (
    <SlideListProvider isBuilderReady={isBuilderReady} video={video}>
      <SlideListContent {...props} />
    </SlideListProvider>
  )
}
