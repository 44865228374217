import Icon from '../../../components/Icon'

import { STYLE } from '../constants'

export const ExportPanoptoModalError = () => {
  return (
    <>
      <p>
        <Icon style={STYLE.errorIcon} name="warning" />
      </p>

      <h1>Something went wrong</h1>

      <p>
        <span>We were unable to export your video. Please try again later or contact your administrator.</span>
      </p>
    </>
  )
}
