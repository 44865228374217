import { useCallback, useMemo, useState } from 'react'
import { useStore } from '../../../../../store'
import { uniqueId } from '../../../../../utils/helpers'
import { replaceEmojisToBreaks, replaceBreaksToEmojis } from './helpers'
import { useElaiNotification } from '../../../../../hooks/useElaiNotification'
import { MARK_EMOJI, MARK_REGEX, TEMPORARY_MARKER_REPLACER } from './constants'

export const useUpdateSpeechTextarea = (textareaRef, setSpeech) => {
  const [localMarksState, setLocalMarksState] = useState([])
  const { updateMarks } = useStore((stores) => stores.videosStore)

  const notification = useElaiNotification()

  const updateMarkState = useCallback(
    (value) => {
      setLocalMarksState(value)
      updateMarks(value)
    },
    [updateMarks],
  )

  const showDroppedMarksWarning = useCallback((total) => {
    notification.warning({
      key: 'dropped-markers-warning',
      message: `The ${MARK_EMOJI.length}-marker limit has been reached. ${total} markers were dropped from the end of the speech. Please check object animations to prevent synchronization issues or unexpected behavior.`,
      duration: 0,
    })
  }, [])

  const regenerateMarksEmojis = useCallback(
    (text) => {
      const textarea = textareaRef.current
      if (!textarea) return
      const { selectionStart, selectionEnd, value } = textarea
      text = text.replaceAll(new RegExp(MARK_EMOJI.join('|'), 'g'), TEMPORARY_MARKER_REPLACER)
      text = value.substring(0, selectionStart) + text + value.substring(selectionEnd)
      const newMap = []
      let totalUnavailableMarks = 0
      let updatedText = text
      let lengthReduce = 0
      const matches = [...text.matchAll(new RegExp([TEMPORARY_MARKER_REPLACER, ...MARK_EMOJI].join('|'), 'g'))]
      matches.forEach((match) => {
        const [emoji] = match
        const { index, input } = match
        const existingMark = localMarksState.find((obj) => obj[emoji])
        const availableEmoji = MARK_EMOJI.filter((emoji) => !newMap.find((obj) => Object.hasOwn(obj, emoji)))[0]
        const stringStart = updatedText.substring(0, index - lengthReduce)
        const stringEnd = updatedText.substring(index + emoji.length - lengthReduce)
        updatedText = stringStart + (availableEmoji ?? '') + stringEnd
        lengthReduce = input.length - updatedText.length

        if (availableEmoji) newMap.push({ [availableEmoji]: existingMark?.[emoji] ?? `${uniqueId()}` })
        else totalUnavailableMarks++
      })
      if (totalUnavailableMarks) showDroppedMarksWarning(totalUnavailableMarks)
      return { newMap, updatedText }
    },
    [localMarksState],
  )

  const handleSpeechChanges = useCallback(
    (text = '', shouldFocus = true) => {
      const textarea = textareaRef.current
      const { selectionStart, selectionEnd, value } = textarea
      const { newMap, updatedText } = regenerateMarksEmojis(text)
      updateMarkState(newMap)
      const insertedLength = updatedText.length - value.length
      textarea.value = updatedText
      textarea.selectionStart = selectionStart + insertedLength
      textarea.selectionEnd = selectionEnd + insertedLength
      if (shouldFocus) textarea.focus()
      setSpeech(updatedText)
    },
    [textareaRef.current, updateMarkState],
  )

  const replaceTagsToEmoji = useCallback((text) => {
    let result = replaceBreaksToEmojis(text)
    const availableMarkers = [...text.matchAll(MARK_REGEX)].map((match) => match[1])
    const newMap = []

    availableMarkers.forEach((marker, index) => {
      newMap.push({ [`${MARK_EMOJI[index]}`]: marker })
      result = result.replace(/<mark name=['"](\d+)['"] \/>/, MARK_EMOJI[index])
    })
    updateMarkState(newMap)
    return result
  }, [])

  const replaceEmojiToTags = useCallback(
    (text) => {
      let result = replaceEmojisToBreaks(text)
      const values = localMarksState.map((obj) => Object.values(obj)[0])
      const keys = localMarksState.map((obj) => Object.keys(obj)[0])
      const emojiPattern = new RegExp(`${MARK_EMOJI.join('|')}`, 'gu')
      result = result.replace(emojiPattern, (match) => {
        const index = keys.indexOf(match)
        return `<mark name="${values[index]}" />`
      })

      return result
    },
    [localMarksState],
  )

  const getMarkValues = useCallback(() => localMarksState.map((obj) => Object.values(obj)[0]), [localMarksState])

  const marksTotal = useMemo(() => localMarksState.length, [localMarksState.length])

  return {
    marksTotal,
    getMarkValues,
    replaceTagsToEmoji,
    replaceEmojiToTags,
    handleSpeechChanges,
  }
}
