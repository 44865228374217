import { SyncOutlined } from '@ant-design/icons'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import { useEffect, useState, useRef, useMemo } from 'react'
import { Row, Col, Button, Spin, Tooltip, Modal } from 'antd'
import Scrollbars from 'react-custom-scrollbars'

import Slide from './slide/slide'
import Icon from '../../components/Icon'
import { Sidebar } from './sidebar/index'
import RenderPopover from '../../components/RenderPopover/renderPopover'
import ShortcutTooltip from './components/shortcutTooltip'

import { useAudioCache } from './useAudioCache'
import { useUpdateSave } from './useUpdateSave'
import { usePlayer } from './usePlayer'
import { useCanvasRegistry } from './useCanvasRegistry'
import { useTemplatesCreation } from './useTemplatesCreation'

import { useStore } from '../../store'
import ColorsEditor from './colorsEditor'

import { request } from '../../utils/api'
import { getDefaultVoice } from '../../utils/videos'
import { queueMicrotask, uniqueId } from '../../utils/helpers'
import { blankSlide, getRenderingTime, formatSeconds } from '../../utils/videos'

import { formatMediaSrc, removeMissingObjects } from '../../utils/fabric/fabric'
import { applyFabricMigration } from '../../utils/fabric/migration'
import { VideoHeader, SlideList, ModeratioStatusPanel } from './components'

import {
  PLAY_ICON,
  PAUSE_ICON,
  HEADER_STYLE,
  UNSAVED_VIDEO_STATUSES,
  MAIN_AREA_STYLE,
  VIDEO_CAMERA_ICON,
  LOADING_OUTLINED_ICON,
  MASK_STYLE,
  WARNING_MODAL_BUTTON_STYLE,
  SHAPES_TYPES,
} from './constants'

import './video.less'
import { useSlideActions } from './useSlideActions'
import { OnboardingModal } from './components/onboardingModal/onboardingModal'
import { useElaiNotification } from '../../hooks/useElaiNotification'
import { useSlideDuration } from '../../hooks/useSlideDuration'
import { useStableCallback } from '../../hooks/useStableCallback'
import { maxVideoNameLength } from '../../utils/constants'
import { APPROX_DURATION_COEFFICIENT } from '../../data/languageDurationCoefficients'
import { InteractivityModal } from './components/interactivityModal/interactivityModal'

const isDebug = process.env.NODE_ENV === 'development'

const Video = () => {
  const location = useLocation()
  const { id, slideId } = useParams()
  const navigate = useNavigate()
  const notification = useElaiNotification()

  // handing video saving and updates. refs used for async state access
  const autoFetchTimer = useRef()
  const videoRef = useRef()
  const playerRef = useRef()
  const activeSlideRef = useRef()
  const unmountedRef = useRef(false)

  const canvasRef = useRef()
  const sidebarRef = useRef()
  const workingAreaRef = useRef()
  const canvasesContainerRef = useRef()
  const deletedVideoWarningModal = useRef()

  const [video, setVideo] = useState()
  const [activeSlide, setActiveSlide] = useState(undefined)
  const [editingName, setEditingName] = useState(false)
  const [isOpenSidebar, setIsOpenSidebar] = useState(true)
  const [isApplyingTemplateSlide, setIsApplyingTemplateSlide] = useState(false)
  const [canvasActiveObject, setCanvasActiveObject] = useState()
  const [assetActiveTab, setAssetActiveTab] = useState('assets')
  const [activeObjectModifier, setActiveObjectModifier] = useState()
  const [activeSidebarTab, setActiveSidebarTab] = useState('elements')

  const voices = useStore((stores) => stores.voicesStore.voices)
  const colorEditorStore = useStore((stores) => stores.colorEditorStore)
  const { user, checkAdminLoginedAsUser } = useStore((stores) => stores.authStore)

  const minutesLeft = useMemo(() => {
    if (video?.status === 'rendering') return getRenderingTime(video).minutesLeftFormatted
    return '0'
  }, [video?.data, video?.status])

  const interactiveQuestion = useMemo(
    () => video?.slides[activeSlide]?.canvas?.objects.find((obj) => obj.type === 'question'),
    [video?.slides[activeSlide]?.canvas?.objects],
  )

  const setActiveSlideWithUrl = (i) => {
    if (!video.slides[i]?.id) i = 0

    setActiveSlide(i)
    navigate(`/video/${id}/${video.slides[i].id}`, { state: location.state, replace: true })
  }

  const updateActiveSlide = (i) => {
    setActiveSlideWithUrl(i)
  }

  const stableUpdateActiveSlide = useStableCallback(updateActiveSlide)

  const handleMissingMedia = (media, slideId) => {
    if (unmountedRef.current) return
    notification.warning({
      key: `missing-media-${slideId}`,
      duration: false,
      message: 'Several media assets from your video are missing',
      description: (
        <>
          If it's a stock video, you can replace it with a different one.
          <p style={{ marginTop: 16 }}>Do you want to remove this media?</p>
          <Button
            type="primary"
            danger="true"
            onClick={() => removeMissingMedia(slideId)}
            loading={videoSavingStatus === 'saving'}
          >
            Remove
          </Button>
          <Button
            type="default"
            style={{ marginLeft: 8 }}
            onClick={() => notification.destroy(`missing-media-${slideId}`)}
          >
            Cancel
          </Button>
        </>
      ),
    })
  }

  const handleCanvasSelected = () => {
    canvasRef.current?.requestObjectsVisibilityUpdate()
  }

  const canvasRegistry = useCanvasRegistry(
    video,
    videoRef,
    Number(slideId),
    workingAreaRef,
    canvasesContainerRef,
    isOpenSidebar,
    activeSlide,
    handleCanvasSelected,
    handleMissingMedia,
  )

  const updatePlayerState = (state) => setPlayer((p) => ({ ...p, ...state }))

  const audioCache = useAudioCache(video)
  const {
    changesHistory,
    undoLastChanges,
    updateSlide,
    updateVideo,
    stableUpdateVideo,
    saveVideo,
    videoSavingStatus,
    setVideoSavingStatus,
  } = useUpdateSave({
    video,
    setVideo,
    videoRef,
    playerRef,
    updatePlayerState,
    activeSlide,
    updateActiveSlide,
    canvasRef,
    canvasRegistry,
    setCanvasActiveObject,
    user,
    checkAdminLoginedAsUser,
    onSaveSuccess: () => canvasRegistry.flushThumbnailsCache(videoRef.current),
    onSaveError: () => canvasRegistry.dropThumbnailCache(videoRef.current),
    // workaround for prevent cross-dependency
    checkSlideInEditingMode: () => playerRef.current.status === 'idle',
  })
  const { player, setPlayer, playVideo, stopVideo, togglePlay, playNextSlide, manualChangingTime } = usePlayer({
    video,
    slide: video?.slides[activeSlide] || {},
    audioCache,
    activeSlide,
    updateVideo,
    updateActiveSlide,
    setActiveSidebarTab,
    setActiveSlideWithUrl,
  })

  const { templateButtons } = useTemplatesCreation({
    video,
    setActiveObjectModifier,
    saveVideo,
    updateVideo,
    canvasRegistry,
  })

  /**
   * Handle all keydown events related to undo and slides
   */
  const { addSlide, stableDeleteSlide, stableDuplicateSlide } = useSlideActions({
    video,
    activeSlide,
    canvasActiveObject,
    canvasRegistry,
    changesHistory,
    updateVideo,
    updateActiveSlide,
    undoLastChanges,
    togglePlay,
  })

  const { slideDurationLimit, slideDurationOrApprox } = useSlideDuration({
    slide: video?.slides[activeSlide] || {},
  })

  /**
   * Auto fetch video during long validation and when rendering is close to finish
   */
  const initAutoFetch = () => {
    if (isDebug) console.log('AutoFetch initialized...')

    const startedAt = Date.now()
    if (autoFetchTimer.current) clearInterval(autoFetchTimer.current)
    let fetchDone = false
    autoFetchTimer.current = setInterval(async () => {
      const video = videoRef.current
      // if validating - just pull video as changes are not allowed
      if (video.status === 'validating') {
        // validation time may not be returned by server or may be lost after page reload, changing current video or smthelse
        const isValidationTimeMissing = !video.data?.validationStartedAt || !video.data?.validationTime
        // regular case
        const isValidationTimeExpired =
          video.data.validationTime &&
          (new Date() - video.data.validationStartedAt) / 1000 - video.data.validationTime > -10

        if (isValidationTimeMissing || isValidationTimeExpired) fetchVideo()
      }

      // if rendering we need to update status only and if it's ready - sync backend and frontend
      // video.data should be here not to grab updates from state
      if (
        video.status === 'rendering' &&
        (getRenderingTime(video).minutesLeft < 2 || startedAt + video.slides.length * 60 * 1000 < Date.now())
      ) {
        const v = await request({ method: 'get', url: `videos/${id}` })
        if (v.status !== 'rendering') {
          let message = v.status === 'error' ? 'Your video was not rendered due to an error.' : 'Your video is ready.'
          setVideoSavingStatus((status) => {
            if (fetchDone) return
            fetchDone = true

            if (status === 'unsaved') {
              saveVideo({ ignoreVideoStatus: true }).then(fetchVideo)
              message += ' Your recent changes were saved as draft.'
            } else fetchVideo()
            notification[v.status === 'error' ? 'error' : 'success']({ message })
            return status
          })
        }
      }
    }, 10000)
  }
  const clearAutoFetch = () => {
    if (autoFetchTimer.current) clearInterval(autoFetchTimer.current)
    autoFetchTimer.current = null

    if (isDebug) console.log('AutoFetch cleared')
  }

  const fetchVideo = async () => {
    const v = await request({ method: 'get', url: `videos/${id}` })
    if (!v) return
    applyFabricMigration(v)

    const notConvertedSlides = !v.slides.every((s) => s.canvas && s.status !== 'story')
    if (notConvertedSlides) return navigate(`/story/${id}`, { replace: true })
    v.data = v.data ?? {}
    let justCreated = false
    // generating slides from data
    if (!v.slides.length) {
      v.slides.push({ ...blankSlide(), newlyAdded: true })
      justCreated = true
    }
    updateVideo(v, { replaceState: true, initialRequest: true, updatedSlideId: +slideId })
    if (justCreated && v.status === 'draft') {
      // force current video state to be synced
      videoRef.current = v
      await saveVideo()
    }
    return v
  }

  // fetching video
  useEffect(() => {
    unmountedRef.current = false
    fetchVideo()

    return () => {
      unmountedRef.current = true
      clearAutoFetch()

      colorEditorStore.resetState()
      canvasRegistry.clearRegistry()

      deletedVideoWarningModal.current?.destroy()
    }
  }, [])

  /**
   * Sync video with related ref for timeout/interval
   * https://felixgerschau.com/react-hooks-settimeout/#using-state-in-settimeout
   */
  useEffect(() => {
    videoRef.current = video

    if (video?.deleted)
      deletedVideoWarningModal.current = Modal.warning({
        title: 'This video has been deleted',
        content: 'You need to restore it to start editing',
        maskStyle: MASK_STYLE,
        footer: (
          <Button type="primary" href="/" style={WARNING_MODAL_BUTTON_STYLE}>
            Back Home
          </Button>
        ),
      })
  }, [video])

  /**
   * Check video status and init autoFetch
   */
  useEffect(() => {
    if (video && UNSAVED_VIDEO_STATUSES.includes(video.status)) initAutoFetch()
    else clearAutoFetch()
  }, [video?.status, video?.data?.validationTime])

  useEffect(() => {
    colorEditorStore.setVisible(false)
    setCanvasActiveObject(null)
    activeSlideRef.current = activeSlide
  }, [activeSlide])

  /**
   * Set default active slide (from URL) and set first slide as default if there is no slide in URL
   */
  useEffect(() => {
    if (video) {
      const slideIndex = video.slides.findIndex((slide) => slide.id === parseInt(slideId))
      if (activeSlide === undefined || slideIndex === -1) setActiveSlide(slideId && slideIndex !== -1 ? slideIndex : 0)
      if (!slideId || slideIndex === -1)
        navigate(`/video/${id}/${video.slides[0].id}`, { state: location.state, replace: true })
    }
  }, [video, id, slideId])

  useEffect(() => {
    playerRef.current = player
  }, [player])

  const handleMainAreaClick = (e) => {
    if (e.target.classList.contains('ant-col')) colorEditorStore.setVisible(false)
  }

  const isBasicPlan = user.account.plan === 'basic'

  // adding slides from sidebar menu
  const addSlideFromTemplate = async ({ template, replaceCurrent }) => {
    const currentSlide = video.slides[activeSlide]
    const { canvas, animation, bg, avatar, language, speech, voiceType } = template

    setIsApplyingTemplateSlide(true)
    if (replaceCurrent) {
      const onlyVisuals = { canvas, bg, animation }
      const { src, tilt } = currentSlide.canvas.objects.find((obj) => obj.type === 'avatar')
      const objects = onlyVisuals.canvas.objects.map((obj) => (obj.type === 'avatar' ? { ...obj, src, tilt } : obj))
      updateSlide({ ...onlyVisuals, canvas: { ...onlyVisuals.canvas, objects } })
      // as we update state we should also update physical canvas to display renews
      await canvasRef.current.updateCanvasFromState(currentSlide.canvas)
    } else {
      const { voice, voiceProvider } =
        ['wsl', 'elevenlabs'].includes(template.voiceProvider) && isBasicPlan
          ? getDefaultVoice(voices, template.avatar.gender, language)
          : template
      const fullCopy = { canvas, animation, bg, avatar, language, speech, voice, voiceType, voiceProvider }
      if (currentSlide.newlyAdded) {
        updateSlide(fullCopy)

        // as we update state we should also update physical canvas to display renews
        await canvasRef.current.updateCanvasFromState(currentSlide.canvas)
      } else {
        video.slides.splice(activeSlide + 1, 0, { id: uniqueId(), ...fullCopy, newlyAdded: true })
        updateActiveSlide(activeSlide + 1)
      }
      updateVideo({ slides: video.slides })
    }
    setIsApplyingTemplateSlide(false)
  }

  const removeMissingMedia = async (slideId) => {
    const slide = videoRef.current.slides.find((s) => s.id === slideId)
    slide.canvas.objects = removeMissingObjects(slide.canvas)
    const currentSlide = videoRef.current.slides[activeSlideRef.current]
    const isSameSlide = currentSlide?.id === slideId
    if (isSameSlide) setPlayer((p) => ({ ...p, canvasReady: false }))
    await saveVideo().then(fetchVideo)
    await notification.destroy(`missing-media-${slideId}`)
    notification.success({
      duration: 2,
      message: 'Missing media was successfully removed',
    })
    if (isSameSlide) {
      canvasRef.current.updateCanvasFromState(currentSlide.canvas)
      canvasRegistry.updateThumbnailImmediately(currentSlide)
    }
  }

  const formatMissingMediaErrors = ({ media }) => {
    return media.map((obj) => (
      <p key={obj.id} style={{ marginBottom: 5 }}>
        Media from {formatMediaSrc(obj.src)} can’t be downloaded. If it’s stock video, just replace with different one.
      </p>
    ))
  }

  const renderVideo = async () => {
    if (player.activePreview) stopVideo()

    /**
     * VALIDATION
     */
    const messages = []
    video.slides.forEach((slide, index) => {
      if (slide.voiceType === 'text') {
        if (!slide.speech) messages.push(`Slide ${index + 1} doesn't have a speech. Please add speech text.`)
      } else if (slide.voiceType === 'file') {
        if (!slide.audioUrl)
          messages.push(`Slide ${index + 1} doesn't have an audio file. Please upload a voice or switch to text.`)
      }
      if (
        slide.duration > slideDurationLimit ||
        slideDurationOrApprox > slideDurationLimit * APPROX_DURATION_COEFFICIENT
      )
        messages.push(`Slide ${index + 1} might be too long. Please reduce this slide to ${slideDurationLimit}s.`)
    })
    if (messages.length) {
      notification.error({
        duration: 10,
        message: messages.map((message, i) => (
          <p key={i} style={{ marginBottom: 5 }}>
            {message}
          </p>
        )),
      })
      return false
    }

    // this is just for frontend. no need to be saved
    updateVideo({ status: 'validating', version: video.version }, { ignoreHistory: true })

    await saveVideo({ ignoreVideoStatus: true })

    notification.success({
      key: 'validating',
      duration: false,
      icon: <SyncOutlined spin />,
      message:
        video.status === 'rendering'
          ? 'Your updates were applied. Please wait while we recalculate rendering time...'
          : "Please wait while we are validating your video. Edits won't be saved...",
    })

    const data = await request({
      method: 'post',
      url: `/videos/render/${video._id}`,
      errorToDescriptionConverter: formatMissingMediaErrors,
      onBeforeHandleError: async () => {
        // weird work around for more weirder bug when notification is not destroyed
        await notification.destroy('validating')
      },
    })

    await notification.destroy('validating')

    if (!data) return fetchVideo()

    if (data.accepted) {
      const newV = await fetchVideo()
      const renderTime = getRenderingTime(newV)

      notification.success({
        duration: 12,
        message: `${
          renderTime.minutesLeft > 25 ? 'We are experiencing a high volume of render requests so your' : 'Your'
        } video will be ready in about ${renderTime.minutesLeftFormatted}. Video duration is ${formatSeconds(
          newV.duration,
        )}. You will receive an email when it's ready.`,
      })
    } else if (data.validationTime) {
      updateVideo(
        {
          status: 'validating',
          data: { ...video.data, validationTime: data.validationTime, validationStartedAt: Date.now() },
        },
        { ignoreHistory: true },
      )
      video.data.waitingTime = data.waitingTime
      video.data.renderStartedAt = new Date()
      notification.success({
        duration: 8,
        message: `Approximate render time is ${
          getRenderingTime(video).minutesLeftFormatted
        }. You will receive an email when the video is ready.`,
      })
    } else if (data.minutesNeeded) {
      notification.warning({
        duration: false,
        message: 'Not enough minutes',
        description:
          'You do not have enough minutes to render this video. Upgrade your account or top up minutes in your profile to continue.',
      })

      await fetchVideo()
    } else {
      notification.error({
        duration: false,
        message: 'Video is not valid',
        description: data.message,
      })
      await fetchVideo()
    }
    audioCache.purgeAll()
  }

  const cancelRender = async () => {
    await request({
      method: 'delete',
      url: `/videos/render/${video._id}`,
    })
    await fetchVideo()
  }

  const playVideoPreview = async () => {
    if (videoSavingStatus === 'unsaved') await saveVideo()
    playVideo()
  }

  const saveVideoName = (videoName) => {
    if (!videoName) {
      notification.error({ message: 'Video name is required' })
      return
    }
    if (videoName.length > maxVideoNameLength) {
      notification.error({ message: `Video name should be less than ${maxVideoNameLength} symbols` })
      return
    }
    updateVideo({ name: videoName }, { ignoreHistory: true, triggerPartialSave: true })
    setEditingName(false)
  }

  const onVideoColorsChange = async (onUndo) => {
    updateVideo({ slides: video.slides }, { onUndo, allowThumbnailsUpdate: true })
    await canvasRef.current.updateCanvasFromState(video.slides[activeSlide].canvas, canvasRef.current.getActiveObject())
  }

  const onChangeColor = () => {
    queueMicrotask(() => canvasRegistry.updateThumbnail(video.slides[activeSlide]))
  }

  if (!video) return <Spin style={{ width: '100%', marginTop: 50 }} />

  const slide = activeSlide >= 0 && video.slides.length && video.slides[activeSlide]
  const isRenderSupportedMode = user.isAdmin || !video?.template

  let headerExtra = []

  if (!player.activePreview) {
    const onSaveClick = async () => {
      if (!UNSAVED_VIDEO_STATUSES.includes(video?.status)) {
        const res = await saveVideo()
        if (res)
          notification.success({ key: 'manul-save', duration: 2, message: 'Your recent changes were saved as draft' })
      }
    }

    headerExtra.push(
      <ShortcutTooltip key="undo" title="Undo" keyName="Z" placement="top">
        <Icon
          name="undo"
          style={{ fontSize: '22px', opacity: changesHistory.length > 1 ? 1 : 0.4, color: '#f3f3f3', marginRight: 4 }}
          onClick={undoLastChanges}
        />
      </ShortcutTooltip>,
      <Tooltip
        key="autoSave"
        placement="top"
        title={
          videoSavingStatus === 'saved'
            ? 'All changes are saved'
            : video.status === 'rendering'
            ? 'Please click "Update" to save changes.'
            : 'Your video has updates that are not saved. Click to save as draft.'
        }
        mouseEnterDelay={0.2}
      >
        <div style={HEADER_STYLE.marginRight20}>
          {videoSavingStatus === 'saved' ? (
            <Icon name="check" style={HEADER_STYLE.checkIcon} onClick={onSaveClick} />
          ) : (
            <SyncOutlined spin style={{ fontSize: '22px', opacity: '0.45', color: '#f3f3f3' }} onClick={onSaveClick} />
          )}
        </div>
      </Tooltip>,
    )
  }

  headerExtra.push(
    video.status === 'rendering' && (
      <Tooltip key="rendering" title={`Your video will be ready in about ${minutesLeft}.`}>
        <Button icon={LOADING_OUTLINED_ICON} disabled={true}>
          Ready in ~{minutesLeft}
        </Button>
      </Tooltip>
    ),
    !player.activePreview && video.status !== 'ready' && (
      <Tooltip title="Preview entire video" key="playStart" placement="top">
        <Button
          disabled={!canvasRegistry.ready}
          loading={player.preloading}
          type="default"
          icon={PLAY_ICON}
          onClick={playVideoPreview}
        >
          Preview
        </Button>
      </Tooltip>
    ),
    player.activePreview &&
      (player.status === 'idle' && !interactiveQuestion ? (
        <Button key="playStop" icon={PLAY_ICON} onClick={() => playNextSlide(video.slides[activeSlide + 1]?.id)}>
          Resume
        </Button>
      ) : (
        <Button key="playStop" icon={PAUSE_ICON} onClick={stopVideo}>
          Stop
        </Button>
      )),
    video.status === 'validating' ? (
      <Tooltip
        key="validate"
        title="Please wait while we are validating your video. It might take a few minutes. Changes won't be saved."
      >
        <Button type="primary" loading={true}>
          Validating
        </Button>
        {user.isAdmin ? (
          <Button danger type="primary" onClick={cancelRender} style={{ marginLeft: 10 }}>
            Cancel render
          </Button>
        ) : null}
      </Tooltip>
    ) : (
      isRenderSupportedMode && (
        <RenderPopover
          key="render-popover"
          user={user}
          video={video}
          renderVideo={renderVideo}
          updateVideo={updateVideo}
          cancelRender={cancelRender}
          duration={player.duration}
        />
      )
    ),
  )

  if (video.template) headerExtra.push(templateButtons)

  if (isRenderSupportedMode && video.url) {
    headerExtra.push(
      <Link to={`/preview/${video._id}`} key="preview">
        {video.status === 'ready' ? (
          <Button icon={VIDEO_CAMERA_ICON}>Watch</Button>
        ) : (
          <Tooltip title="Watch rendered version of this video" placement="topLeft">
            <Button icon={VIDEO_CAMERA_ICON} />
          </Tooltip>
        )}
      </Link>,
    )
  }

  if (video.deleted) {
    headerExtra = []
  }

  if (activeSlide === undefined) return <Spin style={{ width: '100%', marginTop: 50 }} />

  const isBuilderReady = player?.canvasReady && !player?.canvasesLocked && canvasRegistry.ready

  return (
    <div className="video">
      <VideoHeader
        id={id}
        video={video}
        navigate={navigate}
        fetchVideo={fetchVideo}
        headerExtra={headerExtra}
        editingName={editingName}
        updateVideo={updateVideo}
        isVideoSaved={videoSavingStatus === 'saved'}
        saveVideoName={saveVideoName}
        playing={player.activePreview}
        setEditingName={setEditingName}
      />
      <Row wrap={false}>
        <Col flex="230px" className="slides">
          <SlideList
            video={video}
            isBuilderReady={isBuilderReady}
            thumbnails={canvasRegistry.thumbnails}
            activeSlide={activeSlide}
            canvasActiveObject={!!canvasActiveObject}
            stableUpdateVideo={stableUpdateVideo}
            stableUpdateActiveSlide={stableUpdateActiveSlide}
            playerCanvasReady={player?.canvasReady}
            playerActivePreview={player?.activePreview}
            playerStatus={player?.status}
            addSlide={addSlide}
            stableDeleteSlide={stableDeleteSlide}
            stableDuplicateSlide={stableDuplicateSlide}
          />
        </Col>
        <Col style={MAIN_AREA_STYLE} onClick={handleMainAreaClick}>
          <Scrollbars className="slide-scrollbar scrollbar">
            <ModeratioStatusPanel isAdmin={user.isAdmin} videoStatus={video.status} moderation={video.moderation} />
            {slide && (
              <Slide
                slide={slide}
                canvasRef={canvasRef}
                video={video}
                undoLastChanges={undoLastChanges}
                updateVideo={updateVideo}
                canvasRegistry={canvasRegistry}
                updateSlide={updateSlide}
                activeSlide={activeSlide}
                updateActiveSlide={updateActiveSlide}
                activeSidebarTab={activeSidebarTab}
                setActiveSidebarTab={setActiveSidebarTab}
                fetchVideo={fetchVideo}
                canvasActiveObject={canvasActiveObject}
                setCanvasActiveObject={setCanvasActiveObject}
                activeObjectModifier={activeObjectModifier}
                setActiveObjectModifier={setActiveObjectModifier}
                player={player}
                // for accessing actual state from outside react context
                playerRef={playerRef}
                setPlayer={setPlayer}
                togglePlay={togglePlay}
                playNextSlide={playNextSlide}
                manualChangingTime={manualChangingTime}
                audioCache={audioCache}
                workingAreaRef={workingAreaRef}
                canvasesContainerRef={canvasesContainerRef}
                interactiveQuestion={interactiveQuestion}
              />
            )}
          </Scrollbars>
        </Col>
        <Col
          flex={isOpenSidebar ? '424px' : '88px'}
          style={{ minWidth: isOpenSidebar ? 424 : 88, display: 'flex', justifyContent: 'flex-end' }}
        >
          {slide && (
            <div>
              <Sidebar
                data={slide}
                video={video}
                stopVideo={stopVideo}
                activeSlide={activeSlide}
                videoRef={videoRef}
                updateVideo={updateVideo}
                stableUpdateVideo={stableUpdateVideo}
                updateSlide={updateSlide}
                player={player}
                activeTab={activeSidebarTab}
                playVideo={playVideoPreview}
                isOpenSidebar={isOpenSidebar}
                setActiveTab={setActiveSidebarTab}
                setIsOpenSidebar={setIsOpenSidebar}
                updateActiveSlide={updateActiveSlide}
                canvasActiveObject={canvasActiveObject}
                setCanvasActiveObject={setCanvasActiveObject}
                addSlideFromTemplate={addSlideFromTemplate}
                setActiveObjectModifier={setActiveObjectModifier}
                isApplyingTemplateSlide={isApplyingTemplateSlide}
                onVideoColorsChange={onVideoColorsChange}
                setAssetActiveTab={setAssetActiveTab}
                assetActiveTab={assetActiveTab}
              />
              <div ref={sidebarRef} className="colors-editor">
                <ColorsEditor
                  video={video}
                  onChangeColor={onChangeColor}
                  onVideoColorsChange={onVideoColorsChange}
                  containerRef={sidebarRef}
                  setActiveTab={setActiveSidebarTab}
                  setAssetActiveTab={setAssetActiveTab}
                  isAllowedTransparent={canvasActiveObject && SHAPES_TYPES.includes(canvasActiveObject.type)}
                />
              </div>
            </div>
          )}
        </Col>
      </Row>
      <InteractivityModal video={video} />
      <OnboardingModal />
    </div>
  )
}

export default Video
