import { useCallback, useRef } from 'react'

/**
 * useStableCallback
 *
 * Returns a callback reference that remains stable between renders
 * while always invoking the latest version of the provided function.
 *
 * Cautions:
 * - The callback reference never changes, so components receiving it won't
 *   automatically re-render if the internal logic updates.
 * - Ensure that your callback does not rely on stale closures.
 *   Use refs or proper dependencies to access up-to-date values.
 *
 * Use Cases:
 * - To prevent unnecessary re-renders in child components that accept a callback prop.
 * - When the child component only needs to invoke the function, without reacting
 *   to changes in its logic.
 */
export const useStableCallback = (callback) => {
  const callbackRef = useRef(callback)
  callbackRef.current = callback

  const handleCallback = useCallback((...args) => {
    return callbackRef.current(...args)
  }, [])

  return handleCallback
}
