import configService from './utils/config'

const { REACT_APP_CONF_URL } = process.env

// TODO change to appropriate url after testing
const url = REACT_APP_CONF_URL ? `${REACT_APP_CONF_URL}/${window.location.hostname}/config.json` : undefined
;(async () => {
  await configService.load(url)
  const { startApp } = require('./bootstrap')
  startApp()
})()

setInterval(() => {
  configService.revalidate(url)
}, 300000)
