import { useState, useMemo, useEffect, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

import { useStore } from '../../../../store'
import { getDefaultVoice } from '../../../../utils/videos'
import { useFilterAvatars } from '../../../../hooks/useFilterAvatars'
import { useStableCallback } from '../../../../hooks/useStableCallback'

import { useElaiNotification } from '../../../../hooks/useElaiNotification'
import { OPTIONS, style } from './constants'
import { track } from '../../../../utils/analytics'

export const useAvatarsTabState = (props) => {
  const notification = useElaiNotification()
  const { data, dataAvatarCode, videoRef, canvasActiveObject, setActiveObjectModifier, stableUpdateVideo } = props

  const [activeAvatar, setActiveAvatar] = useState()
  const [avatarList, setAvatarList] = useState()
  const [selectedLooks, setSelectedLooks] = useState('All avatars')
  const [activeAvatarTab, setActiveAvatarTab] = useState('general')
  const [isOpenConfirmType, setIsOpenConfirmType] = useState(false)
  const [isOpenPhotoAvatarModal, setIsOpenPhotoAvatarModal] = useState(false)
  const [avatarDrawer, setAvatarDrawer] = useState({ isOpen: false, avatar: null })
  const [isOpenConfirmAvatar, setIsOpenConfirmAvatar] = useState({ name: '', open: false })
  const [isOpenFiltersPopup, setIsOpenFiltersPopup] = useState(false)

  const voices = useStore((stores) => stores.voicesStore.voices)
  const avatarsData = useStore((stores) => stores.videosStore.avatars)
  const { isAdmin, account } = useStore((stores) => stores.authStore.user)

  const { filteredAvatars, filters, setFilters, setSearchFilter } = useFilterAvatars({ avatars: avatarList })

  const filteredSelectedAvatars = useMemo(() => {
    const avatarCode = dataAvatarCode?.split('.')?.[0]
    return filteredAvatars?.filter((avatar) => avatar.code === avatarCode)
  }, [dataAvatarCode, filteredAvatars])

  useEffect(() => {
    if (!avatarsData?.length) return
    let result = avatarsData.filter((avatar) => !avatar.accountId && avatar.type !== 'photo')
    if (!account.premiumAvatarsAllowed) result = result.filter((avatar) => !avatar.premium)
    setAvatarList(result)
  }, [avatarsData])

  useEffect(() => {
    if (activeAvatar?.avatar) {
      track('editor_preview_avatar', {
        avatarCode: activeAvatar.avatar.code,
        premium: activeAvatar.avatar.premium || false,
      })
    }
  }, [activeAvatar?.avatar])

  const onChooseAvatar = (avatar, avatarGroup) => {
    const avatarCode = avatarGroup?.code ? `${avatarGroup.code}.${avatar.code}` : avatar.code

    track('editor_set_avatar', { avatarCode, premium: avatarGroup?.premium || false })

    if (avatarGroup) {
      avatar = { ...avatarGroup, ...avatar, code: avatarCode }
      delete avatar.variants
      delete avatar.items
    }

    const { code, gender, type, limit, accountId, canvas } = avatar

    let linkedSlideChanges = {
      avatar: { code, gender, type, limit, accountId, canvas },
      status: data.status,
    }

    const avatarChanges = { url: avatar.canvas, tilt: avatar.tilt }

    if (data.avatar.code !== avatarCode) linkedSlideChanges.status = 'edited'

    if (data.avatar.gender !== avatar.gender && !avatar.defaultVoice) {
      linkedSlideChanges = { ...linkedSlideChanges, ...getDefaultVoice(voices, avatar.gender, data.language) }
    } else if (avatar.defaultVoice) {
      const [voiceProvider, voiceId] = avatar.defaultVoice.split('@')
      linkedSlideChanges.voice = voiceId
      linkedSlideChanges.voiceProvider = voiceProvider
    }

    if (canvasActiveObject.avatarType === 'voiceover') {
      linkedSlideChanges.status = 'edited'
      linkedSlideChanges.voiceType = data.voiceType !== 'silence' ? data.voiceType : 'text'
      avatarChanges.avatarType = 'transparent'
      avatarChanges.videoFormat = videoRef.current?.data?.format
    }

    setActiveObjectModifier({
      change: 'presenter',
      value: avatarChanges,
      async: true,
      linkedSlideChanges,
    })
    if (videoRef.current?.slides.length > 1) {
      setIsOpenConfirmAvatar({ name: (avatarGroup?.name ?? '') + ' ' + (avatar?.name ?? ''), open: true })
    }
  }

  const onStableChooseAvatar = useStableCallback(onChooseAvatar)

  const addListeningAvatar = useCallback(
    (avatar) => {
      setActiveObjectModifier({
        newObject: 'avatar_listener',
        value: avatar.listeningAvatar,
      })
    },
    [setActiveObjectModifier],
  )

  const onAvatarClick = useCallback((avatar) => {
    setAvatarDrawer({ isOpen: true, avatar })
  }, [])

  const applyAvatarToAllSlides = async ({ applyLook, applyType, applyAvatar }) => {
    const avatar = data.canvas.objects.find((obj) => obj.type === 'avatar')
    const slides = videoRef.current?.slides.map((slide) => {
      let returnValue = slide
      const objects = slide.canvas.objects
      const currentAvatar = objects.find((obj) => obj.type === 'avatar')
      if (!currentAvatar) return returnValue
      const isChangedAvatar = slide.avatar.code !== data.avatar.code
      if (applyLook) {
        currentAvatar.src = avatar.src
        currentAvatar.tilt = avatar.tilt
        returnValue = {
          ...slide,
          canvas: { ...slide.canvas, objects },
          status: 'edited',
        }
      }
      if (applyType) {
        currentAvatar.avatarType = avatar.avatarType
        currentAvatar.fill = avatar.fill
        returnValue = {
          ...slide,
          canvas: { ...slide.canvas, objects },
          status: 'edited',
        }
      }
      if (applyAvatar) {
        if (slide.avatar.gender !== data.avatar.gender) {
          returnValue = {
            ...slide,
            canvas: { ...slide.canvas, objects },
            avatar: data.avatar,
            status: 'edited',
          }
          returnValue = { ...returnValue, ...getDefaultVoice(voices, data.avatar.gender, data.language) }
        } else {
          returnValue = {
            ...slide,
            canvas: { ...slide.canvas, objects },
            avatar: data.avatar,
            status: isChangedAvatar ? (slide.status !== 'edited' ? 'audioReady' : 'edited') : slide.status,
          }
        }
      }
      return returnValue
    })
    stableUpdateVideo({ slides }, { allowThumbnailsUpdate: true })
    notification.success({ message: 'Avatar was changed on all slides', duration: 2 })
  }

  const handleAvatarType = (value) => {
    setActiveObjectModifier({
      change: 'avatarType',
      value,
      linkedSlideChanges: {
        status: 'edited',
        voiceType: data.voiceType !== 'silence' ? data.voiceType : 'text',
      },
    })
    if (videoRef?.current?.slides.length > 1) setIsOpenConfirmType(true)
  }

  // if clicked on circle that is already selected trigger avatar change to show applyAll confirm
  const handleAvatarTypeClick = (e) => {
    if (e?.target?.textContent === 'In circle') handleAvatarType('circle')
  }

  const onChangeColor = (color) => setActiveObjectModifier({ change: 'color', value: color })

  const title = useMemo(
    () => <span style={style.fontWeight700}>{avatarDrawer?.avatar?.name}</span>,
    [avatarDrawer?.avatar?.name],
  )

  const onAvatarDrawerChange = useCallback(() => setAvatarDrawer({ isOpen: false }), [])

  const customAvatars = useMemo(() => avatarsData.filter((avatar) => avatar.accountId), [avatarsData])

  const handleChangeAvatarsTab = (v) => {
    onAvatarDrawerChange()
    setActiveAvatarTab(v)
  }

  const handleClickPhotoAvatar = useCallback(
    (avatar) => {
      if (avatar.variants?.length > 1) onAvatarClick(avatar)
      else if (avatar.variants?.length) {
        onStableChooseAvatar(avatar.variants[0], avatar)
        onAvatarDrawerChange()
      } else {
        onStableChooseAvatar(avatar)
        onAvatarDrawerChange()
      }
    },
    [onStableChooseAvatar, onAvatarDrawerChange, onAvatarClick],
  )

  const onPopconfirmCancel = () => {
    setIsOpenConfirmType(false)
    setIsOpenConfirmAvatar({ name: '', open: false })
  }

  const onPopconfirmTypeApply = () => {
    applyAvatarToAllSlides({ applyType: true })
  }

  const onPopconfirmTypeOpenChange = () => setIsOpenConfirmType((p) => !p)

  const onPopconfirmAvatarApply = () => applyAvatarToAllSlides({ applyLook: true, applyAvatar: true })

  const onPopconfirmAvatarOpenChange = () => setIsOpenConfirmAvatar((p) => ({ ...p, open: !p.open }))

  const onChooseAvatarViaPreviewModal = () => {
    if (!isNaN(activeAvatar?.variantIndex)) {
      onChooseAvatar(activeAvatar.avatar.variants[activeAvatar.variantIndex], activeAvatar.avatar)
    } else if (activeAvatar.avatar.variants?.length) {
      onChooseAvatar(activeAvatar.avatar.variants[0], activeAvatar.avatar)
    } else {
      onChooseAvatar(activeAvatar.avatar)
    }
    setActiveAvatar(null)
  }

  const popconfirmApplyProps = {
    icon: '',
    okText: 'Apply to all',
    showCancel: false,
    disabled: videoRef.current?.slides.length < 2,
    onCancel: onPopconfirmCancel,
  }

  const popConfirmOption = useMemo(
    () => OPTIONS.find((o) => o.value === canvasActiveObject?.avatarType),
    [canvasActiveObject?.avatarType],
  )

  const resetActiveAvatar = () => {
    setActiveAvatar(null)
  }

  const navigate = useNavigate()
  const stableNavigate = useStableCallback(navigate)

  return {
    avatarsData,
    avatarList,
    filteredAvatars,
    filteredSelectedAvatars,
    title,
    customAvatars,
    avatarDrawer,
    setAvatarDrawer,
    isAdmin,
    account,
    activeAvatar,
    setActiveAvatar,
    isOpenPhotoAvatarModal,
    setIsOpenPhotoAvatarModal,
    activeAvatarTab,
    setActiveAvatarTab,
    isOpenConfirmAvatar,
    setIsOpenConfirmAvatar,
    isOpenConfirmType,
    setIsOpenConfirmType,
    filters,
    setFilters,
    isOpenFiltersPopup,
    setIsOpenFiltersPopup,
    popconfirmApplyProps,
    onStableChooseAvatar,
    onAvatarClick,
    applyAvatarToAllSlides,
    handleAvatarType,
    handleAvatarTypeClick,
    onChangeColor,
    onAvatarDrawerChange,
    addListeningAvatar,
    handleChangeAvatarsTab,
    handleClickPhotoAvatar,
    onPopconfirmTypeApply,
    onPopconfirmTypeOpenChange,
    onPopconfirmAvatarApply,
    onPopconfirmAvatarOpenChange,
    selectedLooks,
    setSelectedLooks,
    setSearchFilter,
    onChooseAvatarViaPreviewModal,
    popConfirmOption,
    resetActiveAvatar,
    stableNavigate,
  }
}
