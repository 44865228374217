import { useNavigate } from 'react-router-dom'
import { ONBOARDING_TEMPLATE_ID } from '../utils/constants'
import { requestCreateVideo } from '../utils/videoCreation/videoCreation'
import { useStore } from '../store'
import { createCheckoutSession } from '../utils/payment'
import { sleep } from '../utils/helpers'
import configService from '../utils/config'

const rewardfulReady = async () => {
  const { globalProd } = configService.get().env
  if (!globalProd || !window.rewardful || window.Rewardful?._initialized) return

  await Promise.race([new Promise((resolve) => window.rewardful('ready', resolve)), sleep(3000)])
}

const useAppRedirect = () => {
  const navigate = useNavigate()

  const authStore = useStore((stores) => stores.authStore)

  const { hideElaiMentions } = configService.get().features

  const navigateToOnboardingVideo = async ({ isNewPanoptoUser } = {}) => {
    if (hideElaiMentions) return

    const videoId = await requestCreateVideo({ data: { templateId: ONBOARDING_TEMPLATE_ID } })
    if (!videoId) return navigate('/')
    navigate(`/video/${videoId}`, { state: { showOnboardingModal: true, isNewPanoptoUser } })
  }

  const handleRedirect = async ({ isJustRegistered } = {}) => {
    // it is required to fill necessary form fields into modals
    await rewardfulReady()

    const redirectTo = localStorage.getItem('redirectTo')
    localStorage.removeItem('redirectTo')

    const purchase = localStorage.getItem('purchase')
    localStorage.removeItem('purchase')
    if (purchase && authStore?.user?.canManageWorkspace) {
      return await createCheckoutSession(JSON.parse(purchase))
    }

    if (redirectTo) return navigate(redirectTo)

    if (isJustRegistered) {
      return await navigateToOnboardingVideo()
    }

    navigate('/')
  }

  return { navigateToOnboardingVideo, handleRedirect }
}

export default useAppRedirect
