import { forwardRef } from 'react'

import { useIsBuilderReady } from '../slideListContext'

const styles = {
  wrapper: { position: 'relative', transform: 'translate(0px, 0px)' },
  container: { width: '180px', height: '101px' },
}

export const SlideItemWrapper = forwardRef(function SlideWrapper(props, ref) {
  const { isActive, children } = props

  const isBuilderReady = useIsBuilderReady()

  return (
    <div
      ref={ref}
      className={`item-container ${isActive ? 'active' : ''} ${isBuilderReady ? '' : 'waiting'}`}
      style={isBuilderReady ? styles.wrapper : styles.wrapperDisabled}
    >
      {children}
    </div>
  )
})
