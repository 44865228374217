const STYLE = {
  wrapper: { marginTop: '24px' },
  divider: { color: '#bdbdbd' },
}

const onDisabledLinkClick = (e) => e.preventDefault()

export const ExportPanoptoModalResultLinks = ({ openInFolderUrl, viewInPanoptoUrl, withViewInPanoptoLink }) => {
  return (
    <p style={STYLE.wrapper}>
      <a
        target="_blank"
        href={openInFolderUrl}
        disabled={!openInFolderUrl}
        rel="noreferrer"
        onClick={!openInFolderUrl ? onDisabledLinkClick : undefined}
      >
        Open in Folder
      </a>

      {withViewInPanoptoLink && (
        <>
          <span style={STYLE.divider}>{' | '}</span>

          <a
            target="_blank"
            href={viewInPanoptoUrl}
            disabled={!viewInPanoptoUrl}
            rel="noreferrer"
            onClick={!viewInPanoptoUrl ? onDisabledLinkClick : undefined}
          >
            View in Panopto
          </a>
        </>
      )}
    </p>
  )
}
