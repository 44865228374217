import { useCallback, useMemo, useState } from 'react'

import { useExportPanopto } from './useExportPanopto'

import { exportPanoptoCheckUnsupportedElements } from '../utils/exportPanoptoCheckUnsupportedElements'

export const useExportPanoptoModal = ({ video, setIsOpen }) => {
  const [folder, setFolder] = useState(null)

  const {
    exportPanopto,
    exportPanoptoData,
    exportPanoptoInitialize,
    exportPanoptoIsLoading,
    exportPanoptoIsError,
    exportPanoptoIsSuccess,
    exportPanoptoIsTimeout,
  } = useExportPanopto()

  const handleClose = useCallback(() => {
    setIsOpen(false)
  }, [setIsOpen])

  const handleSubmit = useCallback(() => {
    exportPanopto({ folderId: folder?.Id, videoId: video?._id })
  }, [exportPanopto, video?.id, folder?.Id])

  const isInitial =
    !exportPanoptoIsLoading && !exportPanoptoIsError && !exportPanoptoIsSuccess && !exportPanoptoIsTimeout

  const openInFolderUrl = folder?.Urls?.FolderUrl
  const viewInPanoptoUrl = exportPanoptoData?.videoLink

  const hasUnsupportedElements = useMemo(() => exportPanoptoCheckUnsupportedElements(video), [video])

  return {
    handleSubmit,
    folder,
    setFolder,
    handleClose,
    exportPanoptoInitialize,
    exportPanoptoIsLoading,
    exportPanoptoIsError,
    exportPanoptoIsSuccess,
    exportPanoptoIsTimeout,
    isInitial,
    openInFolderUrl,
    viewInPanoptoUrl,
    hasUnsupportedElements,
  }
}
