import { useEffect, useState, useMemo, useCallback } from 'react'
import { useLocation } from 'react-router-dom'
import { track } from '../../utils/analytics'
import { Row, Col, Modal, Button, Card, Switch, Select, Table, Typography } from 'antd'
import { RightOutlined, DownOutlined, UpOutlined } from '@ant-design/icons'
import './upgradePlanModal.less'
import { plansPrices, minutesBasic, minutesAdvanced, plansData, plansPricesAnnually } from '../../data/plans'
import rocket from '../../assets/images/rocket.png'
import check from '../../assets/images/check.svg'
import cross from '../../assets/images/cross.svg'
import arrow from '../../assets/images/arrow-upgrade.svg'
import Scrollbars from 'react-custom-scrollbars'
import { createCheckoutSession } from '../../utils/payment'
import { MinutesNotification } from '../MinutesNotification'

const UpgradePlanModal = ({ isOpen, setIsOpen }) => {
  const [loading, setLoading] = useState(false)
  const [annual, setAnnual] = useState(true)
  const [selectedMinutesBasic, setSelectedMinutesBasic] = useState(15)
  const [selectedMinutesAdvanced, setSelectedMinutesAdvanced] = useState(50)
  const [openedPlans, setOpenedPlans] = useState({ basic: false, advanced: false, custom: false })
  const location = useLocation()

  const redirectUrl = new URLSearchParams(window.location.search).get('redirectUrl')

  const checkout = async (plan) => {
    setLoading(plan)

    const minutes = plan === 'basic' ? selectedMinutesBasic : selectedMinutesAdvanced
    const price = plansPrices[`${plan}_${annual ? 'annual' : 'monthly'}_${minutes}`]
    track('begin_checkout', {
      currency: 'USD',
      value: price,
      subscription_duration: annual ? 'annual' : 'monthly',
      items: [
        {
          item_name: `${plan}_${annual ? 'annual' : 'monthly'}_${minutes}`,
          price,
        },
      ],
    })

    await createCheckoutSession({ plan, annual, minutes, backUrl: redirectUrl ?? location.pathname + '#subscription' })
    setTimeout(() => setLoading(false), 1000)
  }

  useEffect(() => {
    if (isOpen && window.location.hash.substring(1) !== 'subscription') {
      window.location.href = window.location.href + 'subscription'
    }
  }, [isOpen])

  const getSelectOptionMinutes = (minutes) => {
    return minutes.map((m) => ({
      value: m,
      label: `${annual ? m * 12 : m} Minutes/${annual ? 'Year' : 'Month'}`,
    }))
  }

  const render = useCallback(
    (v, { key }) => {
      if (typeof key === 'string') {
        if (key.includes('price')) {
          if (v === 'custom') return 'Custom'
          if (key === 'price_monthly')
            return `$${plansPrices[`${v}_monthly_${v === 'basic' ? selectedMinutesBasic : selectedMinutesAdvanced}`]}`
          return `$${plansPricesAnnually[`${v}_${v === 'basic' ? selectedMinutesBasic : selectedMinutesAdvanced}`]}`
        } else if (key === 'minutes') {
          if (v === 'custom') return 'Custom'
          return v === 'basic' ? selectedMinutesBasic : selectedMinutesAdvanced
        }
      } else if (typeof v === 'boolean')
        return v ? <img src={check} alt="Check icon" /> : <img src={cross} alt="Cross icon" />
      else return v
    },
    [selectedMinutesBasic, selectedMinutesAdvanced],
  )

  const columns = useMemo(
    () => [
      {
        dataIndex: 'name',
        key: 'name',
        width: '35%',
      },
      {
        dataIndex: 'basic',
        key: 'basic',
        align: 'center',
        width: '15%',
        render,
      },
      {
        dataIndex: 'advanced',
        key: 'advanced',
        align: 'center',
        width: '15%',
        render,
      },
      {
        dataIndex: 'custom',
        key: 'custom',
        align: 'center',
        width: '15%',
        render,
      },
    ],
    [render],
  )

  return (
    <Modal
      style={{ top: 30 }}
      open={isOpen}
      width={1100}
      footer={null}
      className="upgrade-modal"
      onCancel={() => {
        setIsOpen(false)
        window.location.hash = ''
      }}
    >
      <Scrollbars className="upgrade-modal-scrollbar scrollbar">
        <Row gutter={[25, 50]} align="bottom">
          <Col span={24} className="desktop">
            <h3>Choose the plan</h3>
            <Switch checkedChildren="Annual" unCheckedChildren="Monthly" onChange={setAnnual} checked={annual} />
          </Col>
          <Col span={24} className="mobile">
            <h3>Choose the plan</h3>
            <div className="mobile-switch-container">
              <div className="discount">
                <div className="discount-text">Save +20%</div>
                <img src={arrow} alt="arrow" />
              </div>
              <span>
                Billed
                <br />
                monthly
              </span>
              <span>
                <Switch onChange={setAnnual} checked={annual} />
              </span>
              <span>
                Billed
                <br />
                annually
              </span>
            </div>
          </Col>
          <Col lg={8} md={8} sm={24} xs={24}>
            <Card className={`${openedPlans.basic ? 'opened' : ''}`}>
              <div className="main-content">
                <h2>Basic</h2>
                <h3>
                  ${plansPrices[`basic_${annual ? 'annual' : 'monthly'}_${selectedMinutesBasic}`]}
                  <span>/month</span>
                </h3>
                <div className="price-annually">
                  {annual ? (
                    <>
                      <Typography.Text type="secondary">
                        ${plansPricesAnnually[`basic_${selectedMinutesBasic}`]} Billed annually
                      </Typography.Text>
                      <div className="discount-text">Save 20%</div>
                    </>
                  ) : (
                    <Typography.Text type="secondary" style={{ margin: '3px 0' }}>
                      Save 20% with annual plan
                    </Typography.Text>
                  )}
                </div>
                <p>
                  $
                  {(
                    plansPrices[`basic_${annual ? 'annual' : 'monthly'}_${selectedMinutesBasic}`] / selectedMinutesBasic
                  ).toFixed(2)}
                  /minute
                </p>
                <Select
                  value={selectedMinutesBasic}
                  options={getSelectOptionMinutes(minutesBasic)}
                  onChange={(v) => setSelectedMinutesBasic(v)}
                />
                <Button
                  loading={loading === 'basic'}
                  type="primary"
                  icon={<RightOutlined />}
                  className="btn-login btn-arrow dark"
                  onClick={() => checkout('basic')}
                >
                  Get started
                </Button>
                <MinutesNotification />
              </div>
              <div className="plans-table-container mobile">
                {plansData.map((data, index) => (
                  <Table
                    key={index}
                    columns={[columns[0], columns.find((column) => column.dataIndex === 'basic')]}
                    dataSource={data}
                    pagination={false}
                    rowClassName="plans-table-row"
                  />
                ))}
              </div>
            </Card>
            <div
              className="btn-open mobile"
              onClick={() => setOpenedPlans({ ...openedPlans, basic: !openedPlans.basic })}
            >
              <span>See all features</span>
              {openedPlans.basic ? <UpOutlined /> : <DownOutlined />}
            </div>
          </Col>
          <Col lg={8} md={8} sm={24} xs={24} className="advanced-col">
            <Card className={`advanced ${openedPlans.basic ? 'opened' : ''}`}>
              <h5>Most popular</h5>
              <img src={rocket} width="60" alt="Rocket" />
              <Card>
                <div className="main-content">
                  <h2>Advanced</h2>
                  <h3>
                    ${plansPrices[`advanced_${annual ? 'annual' : 'monthly'}_${selectedMinutesAdvanced}`]}
                    <span>/month</span>
                  </h3>
                  <div className="price-annually">
                    {annual ? (
                      <>
                        <Typography.Text type="secondary">
                          ${plansPricesAnnually[`advanced_${selectedMinutesAdvanced}`]} Billed annually
                        </Typography.Text>
                        <div className="discount-text">Save 20%</div>
                      </>
                    ) : (
                      <Typography.Text type="secondary" style={{ margin: '3px 0' }}>
                        Save 20% with annual plan
                      </Typography.Text>
                    )}
                  </div>
                  <p>
                    $
                    {(
                      plansPrices[`advanced_${annual ? 'annual' : 'monthly'}_${selectedMinutesAdvanced}`] /
                      selectedMinutesAdvanced
                    ).toFixed(2)}
                    /minute
                  </p>
                  <Select
                    value={selectedMinutesAdvanced}
                    options={getSelectOptionMinutes(minutesAdvanced)}
                    onChange={(v) => setSelectedMinutesAdvanced(v)}
                  />
                  <Button
                    loading={loading === 'advanced'}
                    type="primary"
                    icon={<RightOutlined />}
                    className="btn-login btn-arrow"
                    onClick={() => checkout('advanced')}
                  >
                    Get started
                  </Button>
                  <MinutesNotification />
                </div>
                <div className="plans-table-container mobile">
                  {plansData.map((data, index) => (
                    <Table
                      key={index}
                      columns={[columns[0], columns.find((column) => column.dataIndex === 'advanced')]}
                      dataSource={data}
                      pagination={false}
                      rowClassName="plans-table-row"
                    />
                  ))}
                </div>
              </Card>
            </Card>
            <div
              className="btn-open mobile"
              onClick={() => setOpenedPlans({ ...openedPlans, basic: !openedPlans.basic })}
            >
              <span>See all features</span>
              {openedPlans.basic ? <UpOutlined /> : <DownOutlined />}
            </div>
          </Col>
          <Col lg={8} md={8} sm={24} xs={24}>
            <Card className={`custom ${openedPlans.basic ? 'opened' : ''}`}>
              <div className="main-content">
                <h2>Custom</h2>
                <h4>Enterprise plan</h4>
                <p>Let’s talk about your needs.</p>
                <Button
                  loading={loading === 'basic'}
                  type="primary"
                  icon={<RightOutlined />}
                  className="btn-login btn-arrow dark"
                  href="https://calendly.com/elai_io/demo?utm_source=app"
                  target="_blank"
                >
                  Book a call
                </Button>
              </div>
              <div className="plans-table-container mobile">
                {plansData.map((data, index) => (
                  <Table
                    key={index}
                    columns={[columns[0], columns.find((column) => column.dataIndex === 'custom')]}
                    dataSource={data}
                    pagination={false}
                    rowClassName="plans-table-row"
                  />
                ))}
              </div>
            </Card>
            <div
              className="btn-open mobile"
              onClick={() => setOpenedPlans({ ...openedPlans, basic: !openedPlans.basic })}
            >
              <span>See all features</span>
              {openedPlans.basic ? <UpOutlined /> : <DownOutlined />}
            </div>
          </Col>
        </Row>
        <div className="plans-table-container">
          {plansData.map((data, index) => (
            <Table
              key={index}
              columns={columns}
              dataSource={data}
              pagination={false}
              rowClassName="plans-table-row desktop"
            />
          ))}
        </div>
        <div className="custom-plan">
          <p>Don’t know what plan to choose or need a custom setup?</p>
          <p>
            <a target="_blank" href="https://calendly.com/elai_io/demo?utm_source=app" rel="noreferrer">
              Contact sales
            </a>{' '}
            to learn more
          </p>
        </div>
      </Scrollbars>
    </Modal>
  )
}

export default UpgradePlanModal
